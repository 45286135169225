import {
    AppEvent,
    ColumnSortDirection,
    DataGridActionBar,
    EntityTarget,
    EventBusInstance,
    ExportFormatter,
    ExportableField,
    ExtendedColumn,
    ExtendedColumnOrColumnGroup,
    FilterBar,
    FlexibleFilterDataGrid,
    FlexibleFilterSISP,
    LogLevel,
    NonSelectableRow,
    OptionTypeBase,
    RowCountComponentProps,
    SearchQuery,
    SearchQueryBuilder,
    SelectColumn,
    SortOrder,
    ToolbarButton,
    ToolbarComponentProps,
    User,
    filter as filterTypes,
    showBanner,
} from '@sprint/sprint-react-components';
import { format, startOfYesterday, subMonths, subWeeks } from 'date-fns';
import _ from 'lodash';
import React, { FunctionComponent, ReactNode, useContext, useEffect, useRef, useState } from 'react';
import { Button } from 'react-bootstrap';
import { Options } from 'react-select';
import Prompt from '../../../CommonComponents/Prompt/Prompt';
import DataGridRepository from '../Api/DataGridRepository';
import DataGridRequest from '../Api/DataGridRequest';
import { LimitsRequest } from '../Api/LimitsRequest';
import ExtraActionBar, { CustomAction } from '../Components/ExtraActionsBar';
import { SimpleFilterRequest, generateSimpleFilterRequestBody } from '../Components/Filters';
import MultipleLimitBanner from '../Components/Limits/MultipleLimitBanner';
import MultipleLimitReachedModal from '../Components/Limits/MultipleLimitReachedModal';
import SingleLimitBanner from '../Components/Limits/SingleLimitBanner';
import SingleLimitReachedModal from '../Components/Limits/SingleLimitReachedModal';
import { SavedInboundView, SavedViewColumns } from '../Components/SavedInboundView';
import SimpleCountsBar from '../Components/SimpleCountsBar';
import SimpleSearchResultCounts from '../Components/SimpleSearchResultCounts';
import ViewsBar from '../Components/ViewsBar';
import { SavedViewType } from '../Components/ViewsModal';
import FilterLocalStorageManager from '../HelperFunctions/FilterLocalStorageManager';
import { calculateNearLimit, orderLimitIssues } from '../HelperFunctions/NearLimitCalculator';
import { ClientGroupType, DateFilters, NearLimitMethod } from '../Models/Enums';
import Limit from '../Models/Limit';
import LimitIssue from '../Models/LimitIssue';
import LimitMeta from '../Models/LimitMeta';
import { RepositoryFactoryContext, UserContext } from '../index';
import './CampusDataGrid.scss';

interface Props {
    repository: DataGridRepository<any>;
    dataGridMeta: DataGridMeta;

    viewsMeta?: ViewsMeta;
    actionBarMeta?: ActionBarMeta;
    addSispMeta?: AddSispMeta;
    editSispMeta?: EditSispMeta;
    previewSispMeta?: PreviewSispMeta;
    deleteModalMeta?: DeleteModalMeta;
    limitsModalMeta?: LimitsModalMeta;
    promptMeta?: PromptMeta;
    learnMoreModal?: ReactNode;
}

interface DateFilter {
    from_date: string | null;
    to_date: string | null;
}

export interface PromptMeta {
    icon: string;
    entitySingular?: string;
    entityPlural?: string;
    iconHeight?: number;
    helpCentreLink?: string;
    header?: string;
    hideAdd?: boolean;
    addLineText?: string;
    addButtonLabel?: string;
    addOnClickOverride?: () => void;
    additionalSubtext?: string[];
}

export interface FilterMeta {
    defaultActiveKey: string;
    fieldFilterSections: filterTypes.FieldFilterSection[];
}

export interface ViewsMeta {
    request: DataGridRequest<any>;
    entityTarget: EntityTarget;
    defaultView: SavedInboundView;
    checkLimitDelegate: () => any;
    limitReachedListName: string;
    pinnedFirstTab?: JSX.Element;
    savedViewType: SavedViewType;
    forceLoadViewId?: number;
    updateViewOnSelect?: boolean;
    groupType?: ClientGroupType;

    displayStringSingular?: string;
    displayStringPlural?: string;
}

export interface ActionBarMeta {
    searchPlaceHolder: string;
    includeCounts: boolean;
    extraActionBarMeta?: ExtraActionBarMeta;
    filterBarMeta?: FilterBarMeta;
    addButtonOverride?: JSX.Element;
    hideAddButton?: boolean;
    alignLeft?: boolean;
}

export interface FilterBarMeta {
    filters: filterTypes.BasicFilter[];
}

export interface ExtraActionBarMeta {
    getEditColumnOptionsDelegate?: (selected: Options<OptionTypeBase>) => Options<OptionTypeBase>;
    filterMeta?: FilterMeta;
    exportMeta?: ExportMeta;
    customActions?: CustomAction[];
}

export interface ExportMeta {
    allowExport: boolean;
    entity: string;
    disclaimer?: React.ReactNode;
    disclaimerTitle?: string;
    allSelected?: boolean;
    noProgress?: boolean;
    exportDisabled?: boolean;
    totalRecords?: number;
}

export interface DataGridMeta {
    uniqueKey: string;
    entitySingular: string;
    entityPlural: string;
    createButtonLocked?: boolean;

    columnOptions: Record<any, ExtendedColumn>;
    defaultColumns: ExtendedColumn[];
    frozenColumns?: ExtendedColumn[];
    draggableColumns?: boolean;
    nonSelectableRows?: (rows: any) => NonSelectableRow[] | null;
    defaultSortColumn?: string;
    defaultSortDirection?: ColumnSortDirection;
    hideSelect?: boolean;

    forceReload?: boolean;
    isLoading?: boolean;
    beforeOnGetRows?: () => void;
    afterOnGetRows?: (results: any) => void;

    autoRefreshInterval?: number;

    /* Used to pass request parameters defined outside the datagrid */
    externalExtendedParameters?: any;

    allowSelectAll?: boolean;
    customSelectAllComponent?: FunctionComponent<RowCountComponentProps>;
    customSelectActionsComponent?: FunctionComponent<ToolbarComponentProps>;
}

export interface AddSispMeta {
    key: string;
    sisp: React.FunctionComponent<any>;
    additionalProps?: Record<string, any>;
    showSisp?: boolean;
}

export interface EditSispMeta {
    sisp: React.FunctionComponent<any>;
    additionalProps?: Record<string, any>;
}

export interface PreviewSispMeta {
    key: string;
    sisp: React.FunctionComponent<any>;
}

export interface DeleteModalMeta {
    modal: React.FunctionComponent<any>;
    additionalProps?: Record<string, any>;
}

export interface LimitsModalMeta {
    limits: LimitMeta[];
}

export interface FilterExtendedColumn extends ExtendedColumn {
    filterFieldKey?: string;
    filterFieldName?: string;
    filterFieldType?: filterTypes.FieldType;
    filterFieldOptions?: () => filterTypes.EnumOption[];
    filterFieldAsyncOptions?: (
        filter: string,
        page?: number,
    ) => Promise<filterTypes.EnumOption[]> | filterTypes.EnumOption[];
    filterFieldAsyncSubOptions?: (
        filter: string,
        page?: number,
    ) => Promise<filterTypes.EnumOption[]> | filterTypes.EnumOption[];
    filterFieldEditReadOnly?: boolean;
}

export interface ExportableExtendedColumn extends ExtendedColumn {
    exportFormatter?: ExportFormatter;
}

const CampusDataGrid: FunctionComponent<Props> = (props: Props) => {
    // Stage: Limits
    const limitsUniqueKey = props.dataGridMeta.uniqueKey + '-limit';
    const limitsRepository = useContext(RepositoryFactoryContext).getApiRepository(new LimitsRequest());
    const [limitsCollection, setLimitsCollection] = useState<Limit[]>([]);
    const [limitIssues, setLimitIssues] = useState<LimitIssue[]>([]);
    // State: General
    const [showAddModal, setShowAddModal] = useState<boolean>(props.addSispMeta?.showSisp ?? false);
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
    const [deleteSelectedRows, setDeleteSelectedRows] = useState<number[]>([]);
    const [reload, setReload] = useState<boolean>(false);
    const user: User = useContext(UserContext);

    const [fetchDataSuccess, setFetchDataSuccess] = useState<boolean>(true);

    const [listLimitReached, setListLimitReached] = useState<boolean>(false);

    // State: CountFilterSISP
    const [combinedFilterRequestBody, setFilterRequestBody] = useState<SimpleFilterRequest>();

    // States: FilterSISP
    const [filterSispShown, setFilterSispShown] = useState(false);
    const [selectedFilterState, setSelectedFilterState] = useState<filterTypes.SelectedFilters[]>([]);
    const filterSispUniqueKey: string = props.dataGridMeta.uniqueKey + '-filter';

    // States: FilterRequest
    const [lastFilterRequest, setLastFilterRequest] = useState<SimpleFilterRequest>();

    // State: Views
    const [activeView, setActiveView] = useState<SavedInboundView | null>(null);
    const [addButtonLocked, setAddButtonLocked] = useState<boolean>(false);

    // State: Columns
    const [columns, setColumns] = useState<ExtendedColumn[]>(props.dataGridMeta.defaultColumns);
    const [enabledColumns, setEnabledColumns] = useState<ExtendedColumn[]>(columns);
    const frozenColumns: ExtendedColumn[] = props.dataGridMeta.frozenColumns ?? [];

    // Filter Bar Functionality
    const [filterBarState, setFilterBarState] = useState<Map<string, OptionTypeBase>>(new Map());
    const filterBarRef = useRef(filterBarState);

    // Prompt
    const promptEntitySingular: string = props.promptMeta?.entitySingular ?? props.dataGridMeta.entitySingular;
    const promptEntityPlural: string = props.promptMeta?.entityPlural ?? props.dataGridMeta.entityPlural;

    const onFilterChangeAction = (filters: Map<string, OptionTypeBase>) => {
        setFilterBarState(filters);
    };

    // State: Counts + Prompt
    const [disableToolbar, setDisableToolbar] = useState<boolean>(true);
    const [showPromptDialogue, setShowPromptDialogue] = useState<boolean | null>(null);

    useEffect(() => {
        setDisableToolbar(showPromptDialogue ?? true);
        if (showPromptDialogue) {
            setShowAddModal(false);
            setFilterSispShown(false);
            setActiveView(null);
        }
    }, [showPromptDialogue]);

    useEffect(() => {
        if (limitsCollection.length < 1) {
            setDisableToolbar(true);
        }
        processLimits();
    }, [limitsCollection]);

    useEffect(() => {
        if (props.limitsModalMeta) {
            if (limitIssues.length > 0) {
                EventBusInstance.publish({
                    topic: 'limit-banner',
                    message: props.limitsModalMeta.limits.length > 1 ? orderLimitIssues(limitIssues) : limitIssues[0],
                    target: limitsUniqueKey,
                });
            } else {
                // Publish with message set to null to let currently showing
                // limit banners (if there are any) that there are no longer
                // any limit issues
                EventBusInstance.publish({
                    topic: 'limit-banner',
                    message: null,
                    target: limitsUniqueKey,
                });
            }
        }
    }, [limitIssues]);

    const processLimits = () => {
        if (props.limitsModalMeta) {
            let limitIssues: LimitIssue[] = [];
            let limitReachedCount = 0;
            limitsCollection.forEach((limit: Limit) => {
                const matchingLimitMeta = props.limitsModalMeta?.limits.find((limitMeta: LimitMeta) => {
                    return limit.enabled !== false && limit.limit != null && limitMeta.limitApiKey === limit.key;
                });

                if (matchingLimitMeta) {
                    let limitIssue: LimitIssue = { used_count: limit.used, limit: limit.limit, near_limit: false };
                    if (matchingLimitMeta.hasOwnProperty('type')) {
                        limitIssue = { ...limitIssue, type: matchingLimitMeta.type };
                    }

                    if (limit.used >= limit.limit) {
                        limitIssues.push(limitIssue);
                        limitReachedCount += 1;
                    } else {
                        if (
                            calculateNearLimit(
                                limit,
                                matchingLimitMeta.nearLimitMethod ?? NearLimitMethod.ONE_LESS,
                                matchingLimitMeta.percentLimit,
                            )
                        ) {
                            limitIssues.push({ ...limitIssue, near_limit: true });
                        }
                    }
                }
            });
            // Lock the Add Button if all the limits have been reached
            setAddButtonLocked(props.limitsModalMeta.limits.length === limitReachedCount);
            setLimitIssues(limitIssues);
        }
    };

    function processLimitsModal() {
        const reachedLimit: LimitIssue[] = limitIssues.filter((limitIssue: LimitIssue) => {
            return !limitIssue.near_limit;
        });
        if (reachedLimit.length > 0 && props.limitsModalMeta) {
            // Show the Limit Modal
            EventBusInstance.publish({
                topic: 'show-hoverover-component',
                message: props.limitsModalMeta.limits.length > 1 ? reachedLimit : reachedLimit[0],
                target: limitsUniqueKey,
            });
        }
    }

    const collectLimits = () => {
        // Collect Limits
        if (props.limitsModalMeta) {
            limitsRepository
                .search(new SearchQueryBuilder().build())
                .then((results: any) => {
                    setLimitsCollection(results.results);
                })
                .catch((err: any) => {
                    showBanner({
                        message: 'Failed to get Limits - ' + (err?.message ?? err),
                        level: LogLevel.ERROR,
                        dismissable: false,
                    });
                    return false;
                });
        }
    };

    // Reload DG when filters change
    useEffect(() => {
        filterBarRef.current = filterBarState;
        setReload(true);
    }, [filterBarState]);

    // Loading filters from local storage if available
    const filterLocalStorageKey = FilterLocalStorageManager.makeKey(props.dataGridMeta.uniqueKey, user.id);

    useEffect(() => {
        updateView(activeView);
        if (activeView) FilterLocalStorageManager.clear(filterLocalStorageKey);
    }, [activeView]);

    useEffect(() => {
        if (
            (selectedFilterState && selectedFilterState.length == 0) ||
            (activeView && activeView?.id != 0) ||
            showPromptDialogue
        )
            return;
        FilterLocalStorageManager.set(filterLocalStorageKey, selectedFilterState);
    }, [selectedFilterState]);

    useEffect(() => {
        EventBusInstance.publish({
            topic: 'data-grid-filter-request',
            message: lastFilterRequest,
        });
    }, [lastFilterRequest]);

    useEffect(() => {
        if (activeView || showPromptDialogue) return;
        setSelectedFilterState(FilterLocalStorageManager.parseFromLocalStorage(filterLocalStorageKey));
    }, []);
    // End filter persistence

    // On Did Mount
    useEffect(() => {
        // Handle creation lockout
        collectLimits();
        listLimitNotifications();
        if (props.dataGridMeta.createButtonLocked) {
            setAddButtonLocked(true);
        } else {
            setAddButtonLocked(false);
        }

        // Listen for opens of any SISPs, and close relevant sisps if needed.
        // Use in conjunction with handling the button clicks of sisp toggles in this DG
        EventBusInstance.subscribe(
            'show-hoverover-component',
            (event: AppEvent<any>) => {
                if (event.target == filterSispUniqueKey) {
                    setFilterSispShown(true);
                } else {
                    setFilterSispShown(false);
                }

                if (event.target == props.addSispMeta?.key) {
                    setShowAddModal(true);
                } else {
                    setShowAddModal(false);
                }
            },
            props.dataGridMeta.uniqueKey,
        );
    }, []);

    useEffect(() => {
        // Filter out columns that should be disabled by permissions
        const permissionFilteredColumns = _.reduce(
            enabledColumns,
            (acc: ExtendedColumn[], column) => {
                // Active or no permission key defined.
                if (column?.permission == 'active' || !column?.permission) {
                    acc.push(column);
                }

                return acc;
            },
            [],
        );

        // Set frozen columns
        setColumns([...frozenColumns, ...permissionFilteredColumns]);
    }, [enabledColumns]);

    useEffect(() => {
        if (reload) {
            setReload(false);
        }
    }, [reload]);

    useEffect(() => {
        if (props.dataGridMeta.forceReload) {
            setReload(props.dataGridMeta.forceReload);
        }
    }, [props.dataGridMeta.forceReload]);

    // External parameters
    useEffect(() => {
        if (props.dataGridMeta.externalExtendedParameters) {
            externalExtendedParameters.current = props.dataGridMeta.externalExtendedParameters;
            setReload(true);
        }
    }, [props.dataGridMeta.externalExtendedParameters]);

    const externalExtendedParameters = useRef(props.dataGridMeta.externalExtendedParameters ?? []);

    const updateView = (activeView: SavedInboundView | null) => {
        if (activeView) {
            // column configuration
            const columnKeysInView = _.map(activeView.dataGridColumns, (column) => column.key);
            const optionalColumnsInView = _.filter(
                _.map(columnKeysInView, (columnKey) => {
                    const optionalColumn = _.find(props.dataGridMeta.columnOptions, { key: columnKey });
                    const viewColumn = _.find(activeView.dataGridColumns, { key: columnKey }) as ExtendedColumn;
                    if (optionalColumn) {
                        return {
                            ...optionalColumn,
                            width: viewColumn.width ?? optionalColumn.width,
                        };
                    }
                }),
            ) as ExtendedColumn[];

            // Old lists will not have any columns saved on them... Use default instead
            setEnabledColumns(
                optionalColumnsInView.length > 0 ? optionalColumnsInView : props.dataGridMeta.defaultColumns,
            );

            // filter configuration
            if (JSON.stringify(selectedFilterState) !== JSON.stringify(activeView.selectedFilters)) {
                setSelectedFilterState(
                    (activeView.selectedFilters as filterTypes.SelectedFilters[]) ??
                        ([] as filterTypes.SelectedFilters[]),
                );
            }
        }
    };

    // DataGridActionBar
    const [filter, setFilter] = useState<string>('');
    const [counts, setCounts] = useState<SimpleSearchResultCounts>({
        currentPage: 0,
        itemCount: 0,
        itemsPerPage: 0,
        totalItems: 0,
        totalPages: 0,
    });

    const makeFilterRequest = (query: SearchQuery, selectedFiltersState?: filterTypes.SelectedFilters[]) => {
        const filter: SimpleFilterRequest = {
            filterRequest: generateSimpleFilterRequestBody(selectedFiltersState ?? []),
            ...query.toPOJO(),
        };

        if (JSON.stringify(filter) !== JSON.stringify(combinedFilterRequestBody)) {
            setFilterRequestBody(filter);
        }
        return filter;
    };
    const processDateFilter = (filter: OptionTypeBase): DateFilter => {
        let from_date: string | null = null;
        let to_date: string | null = null;
        const current_date: Date = new Date();
        switch (filter.value) {
            case DateFilters.TODAY:
                from_date = format(current_date, 'yyyy-MM-dd');
                to_date = format(current_date, 'yyyy-MM-dd');
                break;
            case DateFilters.YESTERDAY:
                const yesterday: Date = startOfYesterday();
                from_date = format(yesterday, 'yyyy-MM-dd');
                to_date = format(yesterday, 'yyyy-MM-dd');
                break;
            case DateFilters.THIS_WEEK:
                const last_week: Date = subWeeks(current_date, 1);
                from_date = format(last_week, 'yyyy-MM-dd');
                to_date = format(current_date, 'yyyy-MM-dd');
                break;
            case DateFilters.THIS_MONTH:
                const last_month: Date = subMonths(current_date, 1);
                from_date = format(last_month, 'yyyy-MM-dd');
                to_date = format(current_date, 'yyyy-MM-dd');
                break;
            case DateFilters.BETWEEN:
                from_date = filter.from;
                to_date = filter.to;
                break;
        }
        return { from_date: from_date, to_date: to_date };
    };

    const onGetRows = async (query: SearchQuery, selectedFiltersState?: filterTypes.SelectedFilters[]) => {
        if (props.dataGridMeta.beforeOnGetRows) {
            props.dataGridMeta.beforeOnGetRows();
        }
        collectLimits();
        // Set filterBar filters
        if (filterBarRef.current.size) {
            // Set these filters as Extended Parameters on the query
            // They are dealt with on the api end
            const extendedParameters: { [key: string]: any } = {};
            filterBarRef.current.forEach((val: OptionTypeBase, key: any) => {
                if (val) {
                    const date = filterBarRef.current.get('date');
                    if (date) {
                        const dates = processDateFilter(date);
                        if (dates.from_date) extendedParameters['from_date'] = dates.from_date;
                        if (dates.to_date) extendedParameters['to_date'] = dates.to_date;
                    } else {
                        extendedParameters[key] = val.value;
                    }
                }
            });
            query.setExtendedParameters({ ...query.getExtendedParameters(), ...extendedParameters });
        }

        // Deal with external extended parameters
        if (externalExtendedParameters.current) {
            query.setExtendedParameters({
                ...query.getExtendedParameters(),
                ...externalExtendedParameters.current,
            });
        }

        // deal with filterRequest
        const filter = makeFilterRequest(query, selectedFiltersState);
        if (!_.isEmpty(filter.filterRequest.selectedFilters)) {
            setLastFilterRequest(filter);
            return props.repository
                .filter(filter)
                .then((results: any) => {
                    if (results.wasCancelled) return results;
                    handleShowPrompt(results, query, selectedFiltersState);
                    setCounts(results.counts);

                    if (props.dataGridMeta.afterOnGetRows) {
                        props.dataGridMeta.afterOnGetRows(results);
                    }

                    return results;
                })
                .catch((err: any) => {
                    setFetchDataSuccess(false);
                    showBanner({
                        message: 'Could not fetch records - ' + (err?.message ?? err),
                    });
                    return null;
                });
        } else {
            // If we don't have a filterRequest we do a simple search
            return props.repository
                .search(query)
                .then((results: any) => {
                    if (results.wasCancelled) return results;
                    handleShowPrompt(results, query, selectedFiltersState);
                    setCounts(results.counts);
                    setLastFilterRequest(filter);

                    if (props.dataGridMeta.afterOnGetRows) {
                        props.dataGridMeta.afterOnGetRows(results);
                    }

                    return results;
                })
                .catch((err: any) => {
                    setFetchDataSuccess(false);
                    showBanner({
                        message: 'Could not fetch records - ' + (err?.message ?? err),
                    });
                    return null;
                });
        }
    };

    const handleShowPrompt = (
        results: any,
        searchQuery: SearchQuery,
        selectedFilters?: filterTypes.SelectedFilters[],
    ) => {
        if (showPromptDialogue) {
            setShowPromptDialogue(props.promptMeta != undefined && results.counts.totalItems == 0);
        }
        if (results.counts.totalItems == 0) {
            const hasBasicFilter: boolean = (searchQuery.toPOJO().filter?.length ?? 0) != 0;

            const queryExtendedParams = searchQuery.getExtendedParameters();
            const hasExtendedParameters: boolean = queryExtendedParams ? queryExtendedParams.length != 0 : false;
            let hasNonExternalExtendedParameters: boolean = false;
            if (queryExtendedParams && hasExtendedParameters) {
                // We want to show a prompt if only the external parameters are visible
                // These are usually used to limit the datagrids somehow and are not user input driven.
                hasNonExternalExtendedParameters = !Object.keys(queryExtendedParams).every(
                    (key) => key in externalExtendedParameters.current,
                );
            }

            const hasSelectedFilters: boolean = selectedFilters ? selectedFilters.length != 0 : false;
            setShowPromptDialogue(
                props.promptMeta != undefined &&
                    !(hasBasicFilter || hasNonExternalExtendedParameters || hasSelectedFilters),
            );
        } else {
            setDisableToolbar(false);
        }
    };

    const deleteRecords = async (ids: number[]): Promise<boolean> => {
        return props.repository
            .delete(ids)
            .then(() => {
                showBanner({
                    message: props.dataGridMeta.entitySingular + '(s) deleted successfully',
                    level: LogLevel.SUCCESS,
                });
                setReload(true);
                return true;
            })
            .catch((err: any) => {
                setFetchDataSuccess(false);
                showBanner({
                    message: 'Could not delete records - ' + (err?.message ?? err),
                });
                return false;
            });
    };

    const handleAddButtonClick = () => {
        if (addButtonLocked) {
            processLimitsModal();
        } else {
            EventBusInstance.publish({
                topic: 'show-hoverover-component',
                message: null,
                target: props.addSispMeta?.key,
            });
        }
    };

    const handleFilterButtonClick = () => {
        EventBusInstance.publish({
            topic: 'show-hoverover-component',
            message: null,
            target: filterSispUniqueKey,
        });
    };

    const listLimitNotifications = () => {
        if (props.viewsMeta) {
            props.viewsMeta
                .checkLimitDelegate()
                .then((res: any) => {
                    setListLimitReached(
                        res.inbound_list_limit ? res.inbound_list_usage >= res.inbound_list_limit : false,
                    );
                    if (res.show_alert) {
                        showBanner({
                            message: '<p class="alert-usage-warning">' + res.alert_message + '</p>',
                            level: LogLevel.ERROR,
                        });
                    }
                })
                .catch((err: any) => {
                    showBanner({
                        message: err.message,
                        level: LogLevel.ERROR,
                    });
                });
        }
    };

    // Reordering Columns
    const compareColumnKeys = (a: any, b: any) => {
        return a.key === b.key;
    };

    const handleReorderColumns = (columns: readonly ExtendedColumnOrColumnGroup<any>[]) => {
        // Reorder only currently works with top level columns so just cast type for now
        setEnabledColumns(
            _.differenceWith(columns as ExtendedColumn[], [SelectColumn, ...frozenColumns], compareColumnKeys),
        );
    };

    const getEditColumnOptionsWithPermissions = (selected: Options<OptionTypeBase>): Options<OptionTypeBase> => {
        if (props.actionBarMeta?.extraActionBarMeta?.getEditColumnOptionsDelegate == undefined) return [];
        const optionSections = props.actionBarMeta.extraActionBarMeta.getEditColumnOptionsDelegate(selected);
        for (const section of optionSections) {
            section.options = _.reduce(
                section.options,
                (acc: OptionTypeBase[], o) => {
                    const column = Object.values(props.dataGridMeta.columnOptions).find((c) => {
                        return c.key == o.columnKey;
                    });

                    if (column?.permission == 'disabled') {
                        o.locked = true;
                        acc.push(o);
                    }

                    // Active or no permission key defined.
                    if (column?.permission == 'active' || !column?.permission) {
                        acc.push(o);
                    }

                    return acc;
                },
                [],
            );
        }
        return optionSections;
    };

    // Component Builders
    const toolbarComponent = (): FunctionComponent<ToolbarComponentProps> | undefined => {
        if (props.dataGridMeta.customSelectActionsComponent || props.deleteModalMeta) {
            return (tprops) => {
                return (
                    <>
                        {props.deleteModalMeta && (
                            <ToolbarButton
                                buttonText="Delete Selected"
                                onButtonPress={(selectedRows: Set<React.Key>) => {
                                    setDeleteSelectedRows(Array.from(selectedRows.values()) as number[]);
                                    setShowDeleteModal(true);
                                }}
                                {...tprops}
                            />
                        )}
                        {props.dataGridMeta.customSelectActionsComponent && (
                            <props.dataGridMeta.customSelectActionsComponent {...tprops} />
                        )}
                    </>
                );
            };
        }

        return undefined;
    };

    const addSispAdditionalProps: Record<string, any> | undefined =
        props.limitsModalMeta && props.limitsModalMeta.limits.length > 1
            ? { limits: limitIssues, ...props.addSispMeta?.additionalProps }
            : props.addSispMeta?.additionalProps;

    return fetchDataSuccess ? (
        <>
            {props.previewSispMeta && <props.previewSispMeta.sisp uniqueKey={props.previewSispMeta.key} />}
            {props.deleteModalMeta && (
                <props.deleteModalMeta.modal
                    repository={props.repository}
                    selectedRowsForDeletion={deleteSelectedRows}
                    dataGridEntitySingular={props.dataGridMeta.entitySingular}
                    dataGridEntityPlural={props.dataGridMeta.entityPlural}
                    modalShown={showDeleteModal}
                    reloadDataGrid={() => setReload(true)}
                    closeModal={() => setShowDeleteModal(false)}
                    onDeleteRows={deleteRecords}
                    {...props.deleteModalMeta.additionalProps}
                />
            )}
            {props.limitsModalMeta && props.limitsModalMeta.limits.length > 1 ? (
                <MultipleLimitReachedModal
                    uniqueKey={limitsUniqueKey}
                    limitNameSingular={props.dataGridMeta.entitySingular}
                    limitNamePlural={props.dataGridMeta.entityPlural}
                />
            ) : (
                <SingleLimitReachedModal
                    uniqueKey={limitsUniqueKey}
                    limitNameSingular={props.dataGridMeta.entitySingular}
                    limitNamePlural={props.dataGridMeta.entityPlural}
                />
            )}
            {props.limitsModalMeta &&
                (props.limitsModalMeta.limits.length > 1 ? (
                    <MultipleLimitBanner
                        uniqueKey={limitsUniqueKey}
                        limitName={props.dataGridMeta.entityPlural.toLowerCase()}
                    />
                ) : (
                    <SingleLimitBanner
                        uniqueKey={limitsUniqueKey}
                        limitName={props.dataGridMeta.entityPlural.toLowerCase()}
                    />
                ))}
            {props.actionBarMeta?.extraActionBarMeta?.filterMeta && (
                <FlexibleFilterSISP
                    isOpen={filterSispShown}
                    onCancel={() => {
                        setFilterSispShown(false);
                    }}
                    onFilterUpdated={(selectedFilters) => {
                        if (
                            selectedFilters &&
                            JSON.stringify(selectedFilters) !== JSON.stringify(selectedFilterState)
                        ) {
                            setSelectedFilterState(selectedFilters);
                        }
                    }}
                    selectedFilters={selectedFilterState}
                    filterSections={props.actionBarMeta?.extraActionBarMeta?.filterMeta.fieldFilterSections}
                    defaultActiveKey={props.actionBarMeta?.extraActionBarMeta?.filterMeta.defaultActiveKey}
                />
            )}
            <div
                style={{
                    opacity: disableToolbar ? 0.5 : 1,
                    pointerEvents: disableToolbar ? 'none' : 'auto',
                }}
            >
                {filterSispShown && props.learnMoreModal}
                {props.viewsMeta && (
                    <ViewsBar
                        entityNamePlural={props.dataGridMeta.entityPlural}
                        targetDisplayStringSingular={
                            props.viewsMeta.displayStringSingular ? props.viewsMeta.displayStringSingular : 'Filter'
                        }
                        targetDisplayStringPlural={
                            props.viewsMeta.displayStringPlural ? props.viewsMeta.displayStringPlural : 'Filters'
                        }
                        count={counts?.totalItems ?? 0}
                        request={props.viewsMeta.request}
                        activeView={activeView || props.viewsMeta.defaultView}
                        onViewSelected={(view) => {
                            if (view === undefined)
                                return setActiveView(props.viewsMeta ? props.viewsMeta.defaultView : null);
                            setActiveView(view);
                        }}
                        forceLoadViewId={props.viewsMeta.forceLoadViewId}
                        onViewUpdated={() => listLimitNotifications()}
                        viewsTarget={props.viewsMeta.entityTarget}
                        selectedColumns={_.map(enabledColumns, (col) => {
                            let width;
                            if (typeof col.width === 'string') {
                                width = Number.parseInt(col.width);
                            }
                            const column: SavedViewColumns = {
                                key: col.key,
                                width,
                            };
                            return column;
                        })}
                        selectedFiltersState={selectedFilterState}
                        listLimitReached={listLimitReached}
                        limitReachedHandler={() => processLimitsModal()}
                        pinnedFirstTab={props.viewsMeta.pinnedFirstTab}
                        savedViewType={props.viewsMeta.savedViewType}
                        updateViewOnSelect={props.viewsMeta.updateViewOnSelect ?? false}
                        groupType={props.viewsMeta.groupType ?? undefined}
                    />
                )}
                {props.actionBarMeta && (
                    <DataGridActionBar
                        onFilterChange={setFilter}
                        filter={filter}
                        filterPlaceholder={props.actionBarMeta.searchPlaceHolder}
                        onAddButtonClick={() => handleAddButtonClick()}
                        addButtonLocked={addButtonLocked}
                        hideAddButton={props.actionBarMeta.hideAddButton}
                        addButtonPlaceholder={
                            showPromptDialogue
                                ? props.promptMeta?.addButtonLabel
                                : ['Create', props.dataGridMeta.entitySingular].join(' ')
                        }
                        addButtonOverride={props.actionBarMeta.addButtonOverride}
                        counts={
                            props.actionBarMeta.includeCounts ? (
                                <SimpleCountsBar
                                    counts={counts}
                                    singularText={props.dataGridMeta.entitySingular}
                                    pluralText={props.dataGridMeta.entityPlural}
                                />
                            ) : undefined
                        }
                        alignLeft={props.actionBarMeta.alignLeft}
                    >
                        {props.actionBarMeta.filterBarMeta && (
                            <>
                                <FilterBar
                                    filters={props.actionBarMeta.filterBarMeta.filters}
                                    onFilterChange={onFilterChangeAction}
                                />
                            </>
                        )}
                        {props.actionBarMeta.extraActionBarMeta && (
                            <ExtraActionBar
                                filterMeta={
                                    props.actionBarMeta.extraActionBarMeta.filterMeta
                                        ? {
                                              filterSispShown: filterSispShown,
                                              setFilterSispShown: handleFilterButtonClick,
                                              onClearFilter: () => {
                                                  const defaultView = props.viewsMeta
                                                      ? props.viewsMeta.defaultView
                                                      : null;
                                                  updateView(defaultView);
                                                  setActiveView(defaultView);
                                                  FilterLocalStorageManager.clear(filterLocalStorageKey);
                                              },
                                              currentFilters: selectedFilterState,
                                          }
                                        : undefined
                                }
                                optionalColumnsMeta={
                                    props.actionBarMeta.extraActionBarMeta.getEditColumnOptionsDelegate
                                        ? {
                                              selectedColumnsForDropdown: _.map(enabledColumns, (column) => {
                                                  return {
                                                      value: Object.keys(props.dataGridMeta.columnOptions).find(
                                                          (key) =>
                                                              props.dataGridMeta.columnOptions[key as unknown as any]
                                                                  .key === column.key,
                                                      ) as string,
                                                      label: column.name,
                                                      columnKey: column.key,
                                                  };
                                              }),
                                              getEditColumnOptions: getEditColumnOptionsWithPermissions,
                                              setEnabledOptionalColumns: (selectedColumns) => {
                                                  setEnabledColumns((prevState) => {
                                                      if (selectedColumns.length > prevState.length) {
                                                          // add new column with default width
                                                          const newColKey = [...selectedColumns].pop()?.value;
                                                          return [
                                                              ...prevState,
                                                              props.dataGridMeta.columnOptions[
                                                                  newColKey as unknown as any
                                                              ],
                                                          ];
                                                      } else {
                                                          // remove column missing in new columns
                                                          const removedCol = _.differenceWith(
                                                              prevState,
                                                              selectedColumns,
                                                              (a, b) => a.key === b.columnKey,
                                                          )[0];
                                                          return _.filter(
                                                              prevState,
                                                              (viewColumn) => viewColumn.key !== removedCol.key,
                                                          );
                                                      }
                                                  });
                                              },
                                          }
                                        : undefined
                                }
                                exportMeta={
                                    props.actionBarMeta?.extraActionBarMeta?.exportMeta?.allowExport
                                        ? {
                                              repository: props.repository,
                                              entity: props.actionBarMeta.extraActionBarMeta.exportMeta.entity,
                                              exportableFields: [
                                                  ..._.map(frozenColumns as ExportableExtendedColumn[], (c) => {
                                                      return {
                                                          key: c.key,
                                                          name: c.name,
                                                          formatter: c.exportFormatter,
                                                      } as unknown as ExportableField;
                                                  }),
                                                  ..._.map(
                                                      {
                                                          ...(props.dataGridMeta.columnOptions as Record<
                                                              any,
                                                              ExportableExtendedColumn
                                                          >),
                                                      },
                                                      (c) => {
                                                          return {
                                                              key: c.key,
                                                              name: c.name,
                                                              formatter: c.exportFormatter,
                                                          } as unknown as ExportableField;
                                                      },
                                                  ),
                                              ],
                                              filters: makeFilterRequest(
                                                  new SearchQueryBuilder(1, 500).build(),
                                                  selectedFilterState,
                                              ),
                                              externalParameters: externalExtendedParameters.current,
                                              selectedExportFieldKeys: columns.map((value) => {
                                                  return value.key;
                                              }),
                                              totalRecords:
                                                  props.actionBarMeta.extraActionBarMeta.exportMeta.totalRecords ??
                                                  counts.totalItems,
                                              disclaimer: props.actionBarMeta.extraActionBarMeta.exportMeta.disclaimer,
                                              disclaimerTitle:
                                                  props.actionBarMeta.extraActionBarMeta.exportMeta.disclaimerTitle,
                                              allSelected:
                                                  props.actionBarMeta.extraActionBarMeta.exportMeta.allSelected,
                                              noProgress: props.actionBarMeta.extraActionBarMeta.exportMeta.noProgress,
                                              exportDisabled:
                                                  props.actionBarMeta.extraActionBarMeta.exportMeta.exportDisabled,
                                          }
                                        : undefined
                                }
                                customActions={props.actionBarMeta.extraActionBarMeta.customActions}
                            />
                        )}
                    </DataGridActionBar>
                )}
            </div>
            {!showPromptDialogue && (
                <FlexibleFilterDataGrid
                    uniqueKey={props.dataGridMeta.uniqueKey}
                    entitySingular={props.dataGridMeta.entitySingular}
                    entityPlural={props.dataGridMeta.entityPlural}
                    basicFilter={filter}
                    columns={columns}
                    hideSelectColumn={props.dataGridMeta.hideSelect ?? false}
                    reload={reload}
                    loading={props.dataGridMeta.isLoading ?? false}
                    onGetRows={onGetRows}
                    onUpdateRow={() => Promise.resolve()}
                    addModal={props.addSispMeta?.sisp}
                    addModalAdditionalProps={addSispAdditionalProps}
                    showAddModal={showAddModal}
                    defaultSortOption={
                        props.dataGridMeta.defaultSortColumn
                            ? {
                                  column: props.dataGridMeta.defaultSortColumn,
                                  direction: props.dataGridMeta.defaultSortDirection ?? SortOrder.DESC,
                              }
                            : undefined
                    }
                    editSisp={props.editSispMeta?.sisp}
                    editSispAdditionalProps={props.editSispMeta?.additionalProps}
                    selectedFilterState={selectedFilterState}
                    onCloseAddModal={() => setShowAddModal(false)}
                    toolbarComponent={toolbarComponent()}
                    enableDraggableColumns={props.dataGridMeta.draggableColumns ?? false}
                    onDeleteSelectedRows={props.deleteModalMeta ? undefined : deleteRecords}
                    onColumnReorder={handleReorderColumns}
                    nonSelectableRows={props.dataGridMeta.nonSelectableRows}
                    autoRefreshInterval={props.dataGridMeta.autoRefreshInterval ?? undefined}
                    selectAllOptions={{
                        allowSelectAll: props.dataGridMeta.allowSelectAll ?? false,
                        customSelectAllComponent: props.dataGridMeta.customSelectAllComponent,
                    }}
                />
            )}
            {showPromptDialogue && (
                <>
                    {props.addSispMeta && (
                        <props.addSispMeta.sisp
                            shown={showAddModal}
                            onClose={() => setShowAddModal(false)}
                            onSuccess={() => {
                                setShowPromptDialogue(false);
                                setReload(true);
                                return true;
                            }}
                            uniqueKey={props.addSispMeta.key}
                            {...addSispAdditionalProps}
                        />
                    )}
                    <div style={{ marginTop: '1rem', marginBottom: '1rem' }}>
                        <Prompt
                            imgSrc={props.promptMeta?.icon}
                            imgHeight={props.promptMeta?.iconHeight}
                            header={props.promptMeta?.header ?? 'No ' + promptEntityPlural + ' Yet'}
                            subHeader={
                                <>
                                    {props.promptMeta?.additionalSubtext && (
                                        <>
                                            {props.promptMeta.additionalSubtext.map((subtext: string) => (
                                                <p>{subtext}</p>
                                            ))}
                                        </>
                                    )}
                                    {(props.promptMeta?.addOnClickOverride || props.addSispMeta) &&
                                        !props.promptMeta?.hideAdd && (
                                            <p>
                                                {props.promptMeta?.addLineText ??
                                                    `Add your first ${promptEntitySingular}`}{' '}
                                                <a
                                                    style={{ cursor: 'pointer' }}
                                                    onClick={() => {
                                                        props.promptMeta?.addOnClickOverride
                                                            ? props.promptMeta.addOnClickOverride()
                                                            : setShowAddModal(true);
                                                    }}
                                                >
                                                    here
                                                </a>
                                                .
                                            </p>
                                        )}
                                    {props.promptMeta?.helpCentreLink && (
                                        <p>
                                            Learn more about {promptEntityPlural.toLowerCase()} in the{' '}
                                            <a target="_blank" href={props.promptMeta?.helpCentreLink}>
                                                help centre
                                            </a>
                                            .
                                        </p>
                                    )}
                                    {(props.promptMeta?.addOnClickOverride || props.addSispMeta) &&
                                        !props.promptMeta?.hideAdd && (
                                            <div className="prompt-btn-container">
                                                <Button
                                                    className="btn btn-primary"
                                                    onClick={() => {
                                                        props.promptMeta?.addOnClickOverride
                                                            ? props.promptMeta.addOnClickOverride()
                                                            : setShowAddModal(true);
                                                    }}
                                                >
                                                    {props.promptMeta?.addButtonLabel ??
                                                        ['Create', promptEntitySingular].join(' ')}
                                                </Button>
                                            </div>
                                        )}
                                </>
                            }
                        />
                    </div>
                </>
            )}
        </>
    ) : (
        <></>
    );
};
export default CampusDataGrid;
