import { Context, createContext } from 'react';

export enum ModulePermissions {
    ENABLED = 'ENABLED',
    DISABLED = 'DISABLED',
    INVISIBLE = 'INVISIBLE',
}

export interface PermissionsState {
    isEnabled: boolean;
    isVisible: boolean;
}

export type PermissionKey =
    | 'customPropertiesDeals'
    | 'customPropertiesTasks'
    | 'clientGroupsCustomProperties'
    | 'clientGroupsEmailTemplates'
    | 'clientGroupsEmails'
    | 'clientGroupsInboundLists'
    | 'clientGroupsOutreachLists'
    | 'edinData'
    | 'edinExportSchools'
    | 'edinExportSchoolEmails'
    | 'edinExportTeachers'
    | 'edinExportTeacherEmails'
    | 'edinDataCounts'
    | 'edinTeacherEmails'
    | 'edinTeachers'
    | 'edinInboundListFilters'
    | 'edusData'
    | 'edusExportSchools'
    | 'edusExportSchoolEmails'
    | 'edusExportTeachers'
    | 'edusExportTeacherEmails'
    | 'edusDataCounts'
    | 'edusTeacherEmails'
    | 'edusTeachers'
    | 'edusInboundListFilters'
    | 'edukData'
    | 'edukExportSchools'
    | 'edukExportSchoolEmails'
    | 'edukExportTeachers'
    | 'edukExportTeacherEmails'
    | 'edukDataCounts'
    | 'edukTeacherEmails'
    | 'edukTeachers'
    | 'edukInboundListFilters'
    | 'edukInspections'
    | 'edukOfstedUrn'
    | 'edukOfstedIdaciQuintile'
    | 'edukOfstedLatestUngradedInspectionNumberSinceLastInspection'
    | 'edukOfstedUngradedInspectionRelatesToUrnOfSchool'
    | 'edukOfstedUrnDuringUngradedInspection'
    | 'edukOfstedLaEstablishmentDuringUngradedInspection'
    | 'edukOfstedNumberUngradedInspectionsSinceLastGradedInspection'
    | 'edukOfstedDateOfLastUngradedInspection'
    | 'edukOfstedUngradedInspectionPublicationDate'
    | 'edukOfstedDidLatestUngradedInspectionConvertToGradedInspection'
    | 'edukOfstedUngradedInspectionOverallOutcome'
    | 'edukOfstedNumberOfMonitoringAndUrgentInspectionsSinceLastGradedInspection'
    | 'edukOfstedInspectionNumberOfLatestGradedInspection'
    | 'edukOfstedInspectionType'
    | 'edukOfstedInspectionTypeGrouping'
    | 'edukOfstedEventTypeGrouping'
    | 'edukOfstedInspectionStartDate'
    | 'edukOfstedInspectionEndDate'
    | 'edukOfstedPublicationDate'
    | 'edukOfstedGradedInspectionRelatesToUrnOfSchool'
    | 'edukOfstedUrnDuringGradedInspection'
    | 'edukOfstedLaEstablishmentDuringGradedInspection'
    | 'edukOfstedOverallEffectivenessScore'
    | 'edukOfstedCategoryOfConcern'
    | 'edukOfstedQualityOfEducationScore'
    | 'edukOfstedBehaviourAndAttitudesScore'
    | 'edukOfstedPersonalDevelopmentScore'
    | 'edukOfstedEffectivenessOfLeadershipAndManagementScore'
    | 'edukOfstedIsSafeguardingEffective'
    | 'edukOfstedEarlyYearsProvisionScore'
    | 'edukOfstedSixthFormProvisionScore'
    | 'edukOfstedPreviousGradedInspectionNumber'
    | 'edukOfstedPreviousInspectionStartDate'
    | 'edukOfstedPreviousInspectionEndDate'
    | 'edukOfstedPreviousPublicationDate'
    | 'edukOfstedPreviousGradedInspectionRelatesToUrnOfSchool'
    | 'edukOfstedUrnDuringPreviousGradedInspection'
    | 'edukOfstedLaEstablishmentDuringPreviousGradedInspection'
    | 'edukOfstedOverallEffectivenessPreviousScore'
    | 'edukOfstedCategoryOfConcernPreviousScore'
    | 'edukOfstedQualityOfEducationPreviousScore'
    | 'edukOfstedBehaviourAndAttitudePreviousScore'
    | 'edukOfstedPersonalDevelopmentPreviousScore'
    | 'edukOfstedEffectivenessOfLeadershipAndManagementPreviousScore'
    | 'edukOfstedIsSafeguardingEffectivePreviousScore'
    | 'edukOfstedEarlyYearsProvisionPreviousScore'
    | 'edukOfstedSixthFormProvisionPreviousScore'
    | 'edukOfstedSource'
    | 'edukOfstedStateOrIndependent'
    | 'moneyQuotesInListBuilder'
    | 'dealsInListBuilder'
    | 'reportEducationData'
    | 'managedCampaigns'
    | 'managedCampaignsUnsubscribes'
    | 'quotesCloseable'
    | 'bulkEditContacts'
    | 'bulkEditOrganisations'
    | 'bulkEditDeals'
    | 'bulkEditTasks'
    | 'crmModule'
    | 'customTags'
    | 'contactsCustomTags'
    | 'organisationsCustomTags'
    | 'dealsCustomTags'
    | 'campaignsCustomTags'
    | 'edukAccessResponsibilities'
    | 'edinAccessResponsibilities'
    | 'edusAccessResponsibilities'
    | 'obfuscateNonClaimedContactEmails'
    | 'revelioPeopleDg';

export type Permissions = Record<PermissionKey, PermissionsState>;

const mapPermissionStates = (perms: Record<PermissionKey, ModulePermissions>): Permissions => {
    const mapped: Permissions = {} as Permissions;
    for (const [k, v] of Object.entries(perms)) {
        mapped[k as keyof typeof perms] = {
            isEnabled: v === ModulePermissions.ENABLED,
            isVisible: v !== ModulePermissions.INVISIBLE,
        };
    }
    return mapped;
};

export let UserPermissionsContext: Context<Permissions>;
if (document.getElementById('user-permissions')) {
    const perms = JSON.parse(String((document.getElementById('user-permissions') as HTMLInputElement).value));
    if (perms) {
        UserPermissionsContext = createContext(mapPermissionStates(perms));
    }
}
