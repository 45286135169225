import { library } from '@fortawesome/fontawesome-svg-core';
import {
    faChalkboardUser,
    faChartSimple,
    faGraduationCap,
    faHouseChimneyUser,
    faMagnifyingGlass,
    faMoneyBillTrendUp,
    faPeople,
    faSchool,
} from '@fortawesome/pro-duotone-svg-icons';

library.add(
    faSchool,
    faPeople,
    faChartSimple,
    faMoneyBillTrendUp,
    faMagnifyingGlass,
    faChalkboardUser,
    faGraduationCap,
    faHouseChimneyUser,
);

const inspectionsNewStart = new Date('2024-02-19');
const inspectionsIsNew = Math.floor(new Date().getTime() - inspectionsNewStart.getTime()) / (1000 * 60 * 60 * 24) < 120;

export const inspectionsNewPill = inspectionsIsNew
    ? ' <span style="display: inline; margin-left: .5em; padding: .3em .6em .3em .6em; font-size: 75%; font-weight: bold; line-height: 1; color: #fff; background-color: #2fc2ad; text-align: center; white-space: nowrap; vertical-align: baseline; border-radius: .25em;">NEW</span>'
    : '';

export const TEACHER = '<i class="fa-duotone fa-graduation-cap fa-fw mr-2"></i> Staff List';
export const GENERAL = '<i class="fa-duotone fa-school fa-fw mr-2"></i> General';
export const PUPILS = '<i class="fa-duotone fa-people fa-fw mr-2"></i> Pupils';
export const EXAMS = '<i class="fa-duotone fa-chart-simple fa-fw mr-2"></i> Exams';
export const FINANCIAL = '<i class="fa-duotone fa-money-bill-trend-up fa-fw mr-2"></i> Financial';
export const INSPECTIONS = `<i class="fa-duotone fa-magnifying-glass fa-fw mr-2"></i> Inspections${inspectionsNewPill}`;
export const WORKFORCE = '<i class="fa-duotone fa-chalkboard-user fa-fw mr-2"></i> Workforce';
export const SOCIOECONOMIC = '<i class="fa-duotone fa-house-chimney-user fa-fw mr-2"></i> Socio-Economic';
export const SCHOOLS = '<i class="fa-duotone fa-school fa-fw mr-2"></i> School';
export const LISTMANAGEMENT = '<i class="fa-duotone fa-regular fa-gear fa-fw mr-2"></i> List Management';
