// defined in footer.php
declare function dictionaryValue(key: string): string;

export enum EducationDataType {
    EDUK = 'EDUK',
    EDUS = 'EDUS',
}

export enum KnowledgeBaseUrlKey {
    TEACHER_EMAILS = 'TEACHER_EMAILS',
    RECEIVE_EMAILS = 'RECEIVE_EMAILS',
    SEND_EMAILS = 'SEND_EMAILS',
    USER_TYPES = 'USER_TYPES',
    EMBED_WEB_CODE = 'EMBED_WEB_CODE',
    SEARCH_FOR_SCHOOL_TEACHER = 'SEARCH_FOR_SCHOOL_TEACHER',
    UNSUBSCRIBE_SCHOOL = 'UNSUBSCRIBE_SCHOOL',
    UNSUBSCRIBE_TEACHER = 'UNSUBSCRIBE_TEACHER',
    CREATE_MARKETING_LIST = 'CREATE_MARKETING_LIST',
    GET_TO_KNOW_CONTACTS = 'GET_TO_KNOW_CONTACTS',
    ADD_CONTACTS = 'ADD_CONTACTS',
    DATA_IMPORT_TEMPLATES = 'DATA_IMPORT_TEMPLATES',
    IMPORTING_CONTACTS = 'IMPORTING_CONTACTS',
    CSV_FILE = 'CSV_FILE',
    IMPORTING_ORGANISATIONS = 'IMPORTING_ORGANISATIONS',
    ADD_SCHOOLS = 'ADD_SCHOOLS',
    ADD_BUSINESSES = 'ADD_BUSINESSES',
    CREATE_FORM = 'CREATE_FORM',
    CREATE_CONTACT_LIST = 'CREATE_CONTACT_LIST',
    CREATE_CUSTOM_FIELD = 'CREATE_CUSTOM_FIELD',
    CONTACT_POINTS_SCORING = 'CONTACT_POINTS_SCORING',
    UNSUBSCRIBE_CONTACT = 'UNSUBSCRIBE_CONTACT',
    ADD_QUOTE = 'ADD_QUOTE',
    FOLLOW_UP_QUOTE = 'FOLLOW_UP_QUOTE',
    SET_UP_MONEY_MODULE = 'SET_UP_MONEY_MODULE',
    VIEW_SALES = 'VIEW_SALES',
    ADD_SALE = 'ADD_SALE',
    PAYMENT_TYPES = 'PAYMENT_TYPES',
    ADD_PAYMENT = 'ADD_PAYMENT',
    CREATE_CREDIT_NOTE = 'CREATE_CREDIT_NOTE',
    MANAGE_PRODUCTS_SERVICES = 'MANAGE_PRODUCTS_SERVICES',
    EMBED_FORM = 'EMBED_FORM',
    LEAD_GENERATION_FORM = 'LEAD_GENERATION_FORM',
    WEBSITE_VISITS = 'WEBSITE_VISITS',
    SET_UP_FRIENDLY_URLS = 'SET_UP_FRIENDLY_URLS',
    ORGANISATION_TYPES = 'ORGANISATION_TYPES',
    CREATE_JOB_ROLES = 'CREATE_JOB_ROLES',
    SMTP_DETAILS = 'SMTP_DETAILS',
    VERIFY_EMAIL = 'VERIFY_EMAIL',
    PLAIN_TEXT_EMAIL = 'PLAIN_TEXT_EMAIL',
    SENDING_PROFILES = 'SENDING_PROFILES',
    EMAIL_TRACKING = 'EMAIL_TRACKING',
    CREATE_EMAIL = 'CREATE_EMAIL',
    IMPORT_CONTACTS = 'IMPORT_CONTACTS',
    IMPORT_ORGANISATIONS = 'IMPORT_ORGANISATIONS',
    OBFUSCATED_EMAILS = 'OBFUSCATED_EMAILS',
    UNTRACEABLE_OPENS = 'UNTRACEABLE_OPENS',
    CREATING_ENGAGEMENT_METHODS = 'CREATING_ENGAGEMENT_METHODS',
    CUSTOM_EDUCATION_FIELDS = 'CUSTOM_EDUCATION_FIELDS',
    CREATE_CUSTOM_PROPERTY = 'CREATE_CUSTOM_PROPERTY',
    GLOSSARY = 'GLOSSARY',
    RESOURCES = 'RESOURCES',
    MARKETING_FUNNEL = 'MARKETING_FUNNEL',
    SALES_THRESHOLD = 'SALES_THRESHOLD',
    BULK_UNSUBSCRIBE = 'BULK_UNSUBSCRIBE',
    CALENDARS = 'CALENDARS',
    MARKETPLACE = 'MARKETPLACE',
    MANAGED_CAMPAIGNS = 'MANAGED_CAMPAIGNS',
    CONTACTS_MARKETING_STATUS = 'CONTACTS_MARKETING_STATUS',
    MANAGED_CAMPAIGN_FAQS = 'MANAGED_CAMPAIGN_FAQS',
    ENGAGED_RECIPIENTS = 'ENGAGED_RECIPIENTS',
    MARKETING_CAMPAIGN_ENGAGED_RECIPIENTS = 'MARKETING_CAMPAIGN_ENGAGED_RECIPIENTS',
    BOTS = 'BOTS',
    CLICK_RATES = 'CLICK_RATES',
    OPEN_RATES = 'OPEN_RATES',
    EMBEDDING_A_FORM = 'EMBEDDING_A_FORM',
    CONTACTS = 'CONTACTS',
    CONTACTS_OVERVIEW = 'CONTACTS_OVERVIEW',
    ORGANISATIONS = 'ORGANISATIONS',
    ADD_DEAL = 'ADD_DEAL',
    ADD_TASK = 'ADD_TASK',
    MONEY_OVERVIEW = 'MONEY_OVERVIEW',
    QUOTES_OVERVIEW = 'QUOTES_OVERVIEW',
    QUOTES = 'QUOTES',
    FORMS_OVERVIEW = 'FORMS_OVERVIEW',
    FORMS = 'FORMS',
    EMAILS_OVERVIEW = 'EMAILS_OVERVIEW',
    EMAILS = 'EMAILS',
    MONEY = 'MONEY',
    EDUCATION_DATA = 'EDUCATION_DATA',
}

export class KnowledgeBaseUrls {
    private static config: { [key in KnowledgeBaseUrlKey]: string } = {
        TEACHER_EMAILS: '/',
        RECEIVE_EMAILS: '/how-to-receive-day-to-day-emails',
        SEND_EMAILS: '/how-to-send-day-to-day-emails',
        USER_TYPES: '/what-are-users',
        EMBED_WEB_CODE: '/embedding-your-campus-web-code',
        SEARCH_FOR_SCHOOL_TEACHER: '/searching-for-a-school-or-teacher',
        UNSUBSCRIBE_SCHOOL: '/unsubscribing-schools',
        UNSUBSCRIBE_TEACHER: '/unsubscribing-teachers',
        CREATE_MARKETING_LIST: '/creating-an-education-data-list',
        GET_TO_KNOW_CONTACTS: '/getting-to-know-your-contacts',
        ADD_CONTACTS: '/adding-contacts',
        DATA_IMPORT_TEMPLATES: '/data-import-templates',
        IMPORTING_CONTACTS: '/importing-contacts',
        CSV_FILE: '/what-is-a-csv-file',
        IMPORTING_ORGANISATIONS: '/importing-organisations',
        ADD_SCHOOLS: '/adding-school-organisations',
        ADD_BUSINESSES: '/adding-business-organisations',
        CREATE_FORM: '/creating-a-form',
        CREATE_CONTACT_LIST: '/creating-a-contact-list',
        CREATE_CUSTOM_FIELD: '/contact-and-organisation-custom-fields',
        CREATE_CUSTOM_PROPERTY: '/contact-and-organisation-custom-fields',
        SALES_THRESHOLD: '/sales-thresholds',
        CONTACT_POINTS_SCORING: '/contact-points-scoring',
        UNSUBSCRIBE_CONTACT: '/unsubscribing-contacts',
        ADD_QUOTE: '/adding-a-quote',
        FOLLOW_UP_QUOTE: '/following-up-quotes',
        SET_UP_MONEY_MODULE: '/setting-up-your-money-module',
        VIEW_SALES: '/viewing-your-sales',
        ADD_SALE: '/adding-a-sale',
        PAYMENT_TYPES: '/payment-types',
        ADD_PAYMENT: '/adding-a-payment',
        CREATE_CREDIT_NOTE: '/creating-a-credit-note',
        MANAGE_PRODUCTS_SERVICES: '/managing-your-products-and-services',
        EMBED_FORM: '/embedding-a-form',
        LEAD_GENERATION_FORM: '/lead-generation-forms',
        WEBSITE_VISITS: '/website-visits',
        SET_UP_FRIENDLY_URLS: '/setting-up-friendly-urls',
        ORGANISATION_TYPES: '/creating-organisation-types',
        CREATE_JOB_ROLES: '/creating-job-roles',
        SMTP_DETAILS: '/organising-your-smtp-details',
        VERIFY_EMAIL: '/verifying-an-email-address',
        PLAIN_TEXT_EMAIL: '/creating-a-plain-text-version',
        SENDING_PROFILES: '/sending-profiles',
        EMAIL_TRACKING: '/email-tracking',
        CREATE_EMAIL: '/creating-an-email',
        IMPORT_CONTACTS: '/importing-contacts',
        IMPORT_ORGANISATIONS: '/importing-organisations',
        OBFUSCATED_EMAILS: '/obfuscated-emails',
        UNTRACEABLE_OPENS: '/untraceable-opens',
        CREATING_ENGAGEMENT_METHODS: '/gdpr-data-processing-module',
        CUSTOM_EDUCATION_FIELDS: '/custom-education-fields',
        GLOSSARY: '/glossary',
        RESOURCES: '/resources',
        MARKETING_FUNNEL: '/contact-points-scoring#1',
        BULK_UNSUBSCRIBE: '/bulk-unsubscribing',
        CALENDARS: '/education-calendars',
        MARKETPLACE: '/what-is-the-marketplace',
        MANAGED_CAMPAIGNS: '/managed-campaign-reporting',
        CONTACTS_MARKETING_STATUS: '/contacts-marketing-status',
        MANAGED_CAMPAIGN_FAQS: '/managed-campaign-faqs',
        ENGAGED_RECIPIENTS: '/engaged-recipients',
        MARKETING_CAMPAIGN_ENGAGED_RECIPIENTS: '/campaign-reporting#understanding-engaged-recipients',
        BOTS: '/bots',
        CLICK_RATES: '/click-rates',
        OPEN_RATES: '/open-rates',
        EMBEDDING_A_FORM: '/embedding-a-form',
        CONTACTS: '/crm#contacts',
        CONTACTS_OVERVIEW: '/crm#contacts',
        ORGANISATIONS: '/crm#organizations',
        ADD_DEAL: '/sales-tools#deals',
        ADD_TASK: '/sales-tools#tasks',
        MONEY_OVERVIEW: '/sales-tools',
        QUOTES_OVERVIEW: '/sales-tools#quotes',
        QUOTES: '/sales-tools#quotes',
        FORMS_OVERVIEW: '/marketing-tools#forms',
        FORMS: '/marketing-tools#forms',
        EMAILS_OVERVIEW: '/marketing-tools#emails',
        EMAILS: '/marketing-tools#emails',
        MONEY: '/sales-tools',
        EDUCATION_DATA: '/education-data',
    };

    public static get = (config_key: KnowledgeBaseUrlKey): string => {
        const knowledge_base_url = dictionaryValue('company.kb_base_url');
        let path = '/';

        const url = this.config[config_key];
        if (url != undefined) {
            path = url;
        }
        return knowledge_base_url + path;
    };
}
